import { useEffect } from 'react'
import { Offers, Coupons } from './pages'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { isDeviceRunningiOS } from './utils/validator'
import './App.css'
import { getParam } from './utils/params'

function App() {
  const token = getParam('token')
  console.log('REACT_APP_VERSION: ', process.env.REACT_APP_VERSION)

  useEffect(() => {
    // to add web history for enable triggering popstate for closing app when press back button
    if (!isDeviceRunningiOS()) {
      const enteredPathAndQuery = window.location.pathname + window.location.search
      window.history.pushState('detail', '', '/pre-app')
      window.history.pushState('detail', '', enteredPathAndQuery)
    }

    if (token) {
      localStorage.setItem('token', token)
    }
    
    window.addEventListener('popstate', function(event) {
      window.parent.postMessage('popstate', '*')
    })
  }, [])

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/offers" element={<Offers />} />
        <Route path="/offers/:offerId" element={<Offers />} />
        <Route path="/coupons" element={<Coupons />} />
        <Route path="/coupons/:couponId" element={<Coupons />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App
