import classNames from 'classnames'
import { Modal } from '../../../common'
import { OFFERS_TYPE } from '../../../utils/constant'
import { addGAEvent } from '../../../utils/addGoogleAnalytic'
const parse = require('html-react-parser')

interface ModalProps {
  idElement: string
  coin?: number
  onClick?: Function
  title?: string
  description?: string
  children?: string | JSX.Element | JSX.Element[]
  isShowModal: boolean
  setIsShowModal: Function
  disabled?: boolean
  offerType?: OFFERS_TYPE
  offerName?: string
}

export const ModalError = ({ idElement, isShowModal, setIsShowModal, title, description }: ModalProps) => {
  return (
    <Modal idElement={idElement} isShowModal={isShowModal}>
      <img id={`${idElement}-icon`} src={`/icons/exclamation-triangle.svg`} alt='exclamation-triangle-icon' className='w-[53px] ml-[29px] mt-[37px]' />
      <div id={`${idElement}-content`} className='p-6'>
        <p id={`${idElement}-title`} className='text-2xl leading-9 font-lotuss text-gray-primary font-bold tracking-wide1'>{parse(title)}</p>
        {description && <p id={`${idElement}-description`} className={classNames('text-gray-empty mb-1 mt-4 font-lotuss text-base font-medium leading-6', { 'hidden': !description })}>{parse(description)}</p>}
      </div>
      <div id={`${idElement}-button`} className='flex mb-6 mx-3'>
        <button
          id={`${idElement}-button-ok`}
          onClick={() => setIsShowModal()}
          type='button'
          className='flex-1 text-white text-lg font-lotuss font-bold p-2 mb-3 rounded-lg bg-blue-iris leading-7'
        >
          ตกลง
        </button>
      </div>
    </Modal>
  )
}

export const ModalConfirmRedeem = ({
  idElement,
  coin,
  isShowModal,
  setIsShowModal,
  onClick,
  disabled,
  offerType,
  offerName,
}: ModalProps) => {
  return (
    <Modal idElement={idElement} isShowModal={isShowModal}>
      <div id={`${idElement}-content`} className='pt-8 p-6 font-lotuss'>
        <p id={`${idElement}-text-confirm`} className='text-2xl leading-9 text-gray-primary font-bold mb-4'>ยืนยันการแลกคอยน์</p>
        <p id={`${idElement}-text-coin`} className='text-gray-empty mb-2 text-base'>ใช้ {coin} คอยน์</p>
        {offerType === OFFERS_TYPE.PREMIUM && <p id={`${idElement}-text-check-item`} className='text-danger text-base'>กรุณาตรวจสอบสินค้าพรีเมียมที่หน้าร้าน<br />ก่อนกด "ยืนยัน" แลกคอยน์</p>}
      </div>
      <div id={`${idElement}-button`} className='flex mb-6 font-lotuss'>
        <button
          id={`${idElement}-button-cancel`}
          type='button'
          className='flex-1 text-lg text-gray-primary p-2 mb-3 mx-2 rounded-lg bg-gray-disable'
          onClick={() => {
            setIsShowModal(!isShowModal)
            addGAEvent({
              event: 'lineliff_lotuss_offer_cancel_redeem',
              offer_name: offerName,
            })
          }}
        >
          ยกเลิก
        </button>
        <button
          id={`${idElement}-button-confirm`}
          disabled={disabled}
          onClick={onClick ? () => onClick() : undefined}
          type='button'
          className='flex-1 text-white text-lg font-bold p-2 mb-3 mx-2 rounded-lg bg-blue-iris disabled:opacity-50'
        >
          ยืนยัน
        </button>
      </div>
    </Modal>
  )
}