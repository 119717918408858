import classNames from 'classnames'
import { Alert, Modal } from '../../../../common'
import { OFFERS_BZB_TYPE } from '../../../../utils/constant'
import { CouponsBzbDetails } from '../../../../services/my-coupon-bff/types'
import { ERROR_GENERAL } from '../../../../utils/error'
const parse = require('html-react-parser')

interface ModalProps {
  idElement: string
  coin?: number
  onClick: Function
  isShowModal: boolean
  setIsShowModal?: Function
  disabled?: boolean
  offerType?: OFFERS_BZB_TYPE
  countdownTime?: string | undefined
  couponsDetails?: CouponsBzbDetails
  error?: number
  alertError?: string
}

export const ModalConfirmRedeem = ({
  idElement,
  coin,
  isShowModal,
  setIsShowModal,
  onClick,
  offerType,
  disabled,
  alertError,
  countdownTime
}: ModalProps) => {
  const errorMessage = alertError === ERROR_GENERAL.NO_INTERNET ? 'ไม่มีการเชื่อมต่ออินเทอร์เน็ต' : 'มีบางอย่างผิดพลาด กรุณาลองอีกครั้ง'

  return (
    <Modal idElement={idElement} isShowModal={isShowModal}>
      <div
        id="offer-details-alert-no-internet-container"
        className={classNames('fixed flex flex-row h-full bottom-[-30%]', {
          hidden: !alertError,
          'bottom-[-36%]': offerType === OFFERS_BZB_TYPE.COUNTDOWN
        })}
      >
        <Alert idElement="offer-details-no-internet" description={errorMessage} icon="information-circle" />
      </div>
      <div id={`${idElement}-content`} className='pt-[32px] px-[24px] font-lotuss mb-[24px]'>
        <p id={`${idElement}-text-confirm`} className='text-2xl leading-9 text-gray-primary font-bold mb-[12px]'>ยืนยันรับสิทธิ์</p>
        <p id={`${idElement}-text-coin`} className='text-gray-empty mb-2 text-base'>ใช้ {coin} คอยน์</p>
        {(offerType === OFFERS_BZB_TYPE.COUNTDOWN && countdownTime) && (
          <p id={`${idElement}-text-check-item`} className='text-danger text-base'>
            คุณมีเวลา { countdownTime } ในการใช้สิทธิ์
          </p>
        )}
        {(offerType === OFFERS_BZB_TYPE.WEB_VIEW) && (
          <p id={`${idElement}-text-check-item`} className='text-danger text-base'>
            ระบบจะลิงก์ไปยังหน้าเบราว์เซอร์<br></br>กรุณาอย่าปิดเบราว์เซอร์จนกว่าจะใช้คูปองสำเร็จ
          </p>
        )}
      </div>
      <div id={`${idElement}-button`} className='flex mb-[24px] px-[12px] font-lotuss'>
        <button
          id={`${idElement}-button-cancel`}
          type='button'
          className='flex-1 text-lg text-gray-primary p-2 mr-[12px] rounded-lg bg-gray-disable'
          onClick={() => {
            if(setIsShowModal) setIsShowModal(!isShowModal)
          }}
        >
          ยกเลิก
        </button>
        <button
          id={`${idElement}-button-confirm`}
          disabled={disabled}
          onClick={onClick ? () => onClick() : undefined}
          type='button'
          className='flex-1 text-white text-lg font-bold p-2 rounded-lg bg-blue-iris disabled:opacity-50'
        >
          ยืนยัน
        </button>
      </div>
    </Modal>
  )
}

export const ModalSuccess = ({
  idElement,
  isShowModal,
  onClick,
  disabled,
  offerType,
  couponsDetails,
}: ModalProps) => {
  return (
    <Modal idElement={idElement} isShowModal={isShowModal}>
      <div className='mx-[28px] mb-[20px] mt-[35px]'>
        <img 
          src='/icons/success-icon.png' 
          width={66} 
          height={66} 
          alt='success-icon' 
          onError={({ currentTarget }) => {
            currentTarget.onerror = null
            currentTarget.src = '/icons/coupon-image-placeholder.svg'
            currentTarget.className = 'rounded-lg w-[66px]'
          }}
        />
      </div>
      <div id={`${idElement}-content`} className='px-[24px] font-lotuss mb-[16px]'>
        <p id={`${idElement}-text-confirm`} className='text-[24px] leading-9 text-gray-primary font-bold mb-[12px]'>
          รับสิทธิ์สำเร็จ
        </p>
        <p id={`${idElement}-text-1`} className='text-gray-empty text-[16px]'>
          ขอบคุณที่ร่วมกิจกรรมกับเรา
        </p>
        {(offerType === OFFERS_BZB_TYPE.LUCKY_DRAW && couponsDetails) && (
          <p id={`${idElement}-text-2`} className='text-gray-empty text-[16px]'>
            {`ประกาศผลผู้ชนะกิจกรรมภายใน ${couponsDetails?.PrivilegeMessage || '15 วัน'}`}
          </p>
        )}
      </div>
      <div id={`${idElement}-button`} className='flex mb-[24px] px-[12px] font-lotuss'>
        <button
          id={`${idElement}-button-confirm`}
          disabled={disabled}
          onClick={onClick ? () => onClick() : undefined}
          type='button'
          className='flex-1 text-white text-lg font-bold p-2 rounded-lg bg-blue-iris disabled:opacity-50'
        >
          ดูสิทธิพิเศษอื่นๆ
        </button>
      </div>
    </Modal>
  )
}

export const getErrorDetails = (error?: number) => {
  switch (error) {
    case 1:
      return {
        title: 'ขออภัย<br/> มีผู้ใช้สิทธิ์ครบตามจำนวนแล้ว',
        description: 'โปรดดูสิทธิพิเศษอื่นๆ',
      }
    case 2:
      return {
        title: 'ขออภัย คุณใช้สิทธิ์ครบตามกำหนดแล้ว',
        description: '',
      }
    case 3:
      return {
        title: 'พบข้อผิดพลาดในการแลกคูปอง',
        description: 'ขออภัย คุณได้กดรับแคมเปญนี้ไปแล้ว กรุณากดรับใหม่อีกครั้งภายหลัง',
      } 
    case 1403:
      return {
        title: 'พบข้อผิดพลาดในการแลกคูปอง',
        description: 'คุณต้องทำตามเงื่อนไขที่ระบุไว้ใน Campaign นี้ก่อนจึงสามารถรับได้กรุณาอ่านเพิ่มเติมในรายละเอียด',
      } 
    case 1406:
      return {
        title: 'พบข้อผิดพลาดในการแลกคูปอง',
        description: 'แคมเปญนี้ไม่สามารถกดรับได้',
      } 
    case 1410:
      return {
        title: 'พบข้อผิดพลาดในการแลกคูปอง',
        description: 'สินค้านี้ไม่สามารถรีดีมได้',
      } 
    case 1414:
      return {
        title: 'พบข้อผิดพลาดในการแลกคูปอง',
        description: 'ขออภัยคุณไม่สามารถแลกคูปองนี้ได้ เนื่องจากคูปองหมดอายุ',
      }
    case 1416:
      return {
        title: 'พบข้อผิดพลาดในการแลกคูปอง',
        description: 'คุณไม่สามารถรับสิทธิ์ได้ เนื่องจากเป็นเวอร์ชั่นเก่า กรุณาอัปเดตเวอร์ชั่นใหม่เพื่อรับสิทธิ์ หากคุณใช้เวอร์ชั่นใหม่ล่าสุดแล้ว ลองออกจากระบบแล้วเข้าสู่ระบบใหม่อีกครั้ง',
      }
    case 1421:
      return {
        title: 'พบข้อผิดพลาดในการแลกคูปอง',
        description: 'คุณไม่ได้รับสิทธิ์ในแคมเปญนี้',
      }
    case 1302:
      return {
        title: 'โลตัสคอยน์ของคุณไม่เพียงพอ',
        description: '',
      }
      
    case 1409:
      return {
        title: 'ขออภัย สิทธิพิเศษนี้หมดอายุแล้ว',
        description: 'โปรดดูสิทธิพิเศษอื่นๆ',
      }
      
    default:
  }
}

export const ModalError = ({ idElement, error, onClick, isShowModal }: ModalProps) => {
  const errorDetails = getErrorDetails(error)

  return (
    <Modal idElement={idElement} isShowModal={isShowModal}>
      <img id={`${idElement}-icon`} src={`/icons/exclamation-triangle.svg`} alt='exclamation-triangle-icon' className='w-[53px] ml-[29px] mt-[37px]' />
      <div id={`${idElement}-content`} className='p-6'>
        <p id={`${idElement}-title`} className='text-2xl leading-9 font-lotuss text-gray-primary font-bold tracking-wide1'>
          {parse(errorDetails?.title)}
        </p>
        <p id={`${idElement}-description`} className='text-gray-empty mb-1 mt-[8px] font-lotuss text-base font-medium leading-6'>
          {parse(errorDetails?.description)}
        </p>
      </div>
      <div id={`${idElement}-button`} className='flex mb-6 mx-3'>
        <button
          id={`${idElement}-button-ok`}
          onClick={() => onClick ? onClick() : undefined}
          type='button'
          className='flex-1 text-white text-lg font-lotuss font-bold p-2 mb-3 rounded-lg bg-blue-iris leading-7'
        >
          ตกลง
        </button>
      </div>
    </Modal>
  )
}
